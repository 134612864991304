import './App.css';
import useWindowDimensions from './helpers/useWindowDimensions';
import { RandomReveal } from 'react-random-reveal'
import { useState,useEffect } from 'react';


function App() {

  const { height, width } = useWindowDimensions();
  let logoHeight = Math.floor(height/10) >= 40? Math.floor(height/10):40
  let logos = Array.from(Array(    Math.ceil(width/logoHeight)   ).keys())
  let rows = Array.from(Array( Math.ceil( height/ (logoHeight*1.5 ))   ).keys())
  const [onlineStatus, setOnlineStatus] = useState(null);


  function getOnlineStatus() {
    fetch('https://us-central1-twitch-webhook.cloudfunctions.net/live/nikkijustine')
      .then(response => response.json())
      .then(data => {
        console.log(data)
        if (data["stream-online"]===null) {
          setOnlineStatus(false)
          return
        }
        if (data["stream-online"]) {
          setOnlineStatus(true)
          return
        }
        setOnlineStatus(false)
        

        
      
      });
    
  }
  useEffect(() => {
    getOnlineStatus()
    const interval = setInterval(() => {
      getOnlineStatus()
      
    }, 300000);
    return () => clearInterval(interval);
  }, []);
  
  
  

  return (
    <div className="App noisy">
      <div className='Nav'>
        <a id='online-status' href="https://www.twitch.tv/nikkijustine">
          <img className='logo-nav' src = "./icons/twitch-icon-w.svg" alt="Twitch Logo"/>
          {onlineStatus?

          <h1 className='online'>ONLINE</h1>
          :
          <h1 className='offline'>OFFLINE</h1>}

          {onlineStatus?
          
          <div id='online-icon'/>
          :
          null}
          


        </a>

        <h1>Nikkijustine</h1>
        
      </div>

      <div className='content'>
        <div id='social-accounts'>
          <a className='social-account'  href="https://www.twitch.tv/nikkijustine">
            <img className='logos' src = "./icons/twitch-icon-w.svg" alt="Twitch Logo"/>
            <h1>nikkijustine</h1>
          </a>
          <a className='social-account' href="https://www.youtube.com/c/NikkiJustineTTV">
            <img className='logos' src = "./icons/youtube-svgrepo-com.svg" alt="Youtube Logo"/>
            <h1>nikkijustinettv</h1>
          </a>
          <a className='social-account' href="https://instagram.com/nikkijustinettv">
            <img className='logos' src = "./icons/iconmonstr-instagram-11.svg" alt="Instagram Logo"/>
            <h1>nikkijustinettv</h1>
          </a>
          <a className='social-account' href="https://twitter.com/Nikkijustinettv">
            <img className='logos' src = "./icons/iconmonstr-twitter-1.svg" alt="Twitter Logo"/>
            <h1>nikkijustinettv</h1>
          </a>
          <a className='social-account' href="https://www.tiktok.com/@nikkijustinettv">
            <img className='logos' src = "./icons/tiktok.svg" alt="Tiktok Logo"/>
            <h1>nikkijustinettv</h1>
          </a>
        </div>

      </div>

        
       
        
        
        
        
      
    </div>
  );
}

export default App;
